import {axiosData} from "@/store/api/axios";

const state = () => ({
  negocios: null || JSON.parse(localStorage.getItem('negocios')),
  negocio: null || JSON.parse(localStorage.getItem('negocio')),
  isnegocios: null || JSON.parse(localStorage.getItem('isnegocios')),
  negociotipo: null || JSON.parse(localStorage.getItem('negociotipo')),
  negociopromotor: null || JSON.parse(localStorage.getItem('negociopromotor')),
  permisos: null || JSON.parse(localStorage.getItem('permisos')),
  permiso: null || JSON.parse(localStorage.getItem('permiso')),
});

const getters = {
  getNegocios(state) {
    return state.negocios;
  },
  getPermisos(state) {
    return state.permisos;
  },
  getNegocio(state) {
    return state.negocio;
  },
  getPermiso(state) {
    return state.permiso;
  },
  getNegocioPromotor(state) {
    return state.negociopromotor;
  },
  getIsNegocios(state) {
    return state.isnegocios;
  },
  getNegocioTipo(state) {
    return state.negociotipo;
  },
};

const actions = {
  setNegocios({ commit }, payload) {
    commit('SET_NEGOCIOS', payload);
    payload
        ? localStorage.setItem('negocios', JSON.stringify(payload))
        : localStorage.removeItem('negocios');
  },
  setNegocio({ commit }, payload) {
    commit('SET_NEGOCIO', payload);
    payload
        ? localStorage.setItem('negocio', JSON.stringify(payload))
        : localStorage.removeItem('negocio');
  },
  setPermisos({ commit }, payload) {
    commit('SET_PERMISOS', payload);
    payload
        ? localStorage.setItem('permisos', JSON.stringify(payload))
        : localStorage.removeItem('permisos');
  },
  setPermiso({ commit }, payload) {
    commit('SET_PERMISO', payload);
    payload
        ? localStorage.setItem('permiso', JSON.stringify(payload))
        : localStorage.removeItem('permiso');
  },
  setNegocioPromotor({ commit }, payload) {
    commit('SET_NEGOCIOPROMOTOR', payload);
    payload
        ? localStorage.setItem('negociopromotor', JSON.stringify(payload))
        : localStorage.removeItem('negociopromotor');
  },
  setIsNegocio({ commit }, payload) {
    commit('SET_ISNEGOCIO', payload);
    payload
        ? localStorage.setItem('isnegocios', JSON.stringify(payload))
        : localStorage.removeItem('isnegocios');
  },
  setNegocioTipo({ commit }, payload) {
    commit('SET_NEGOCIOTIPO', payload);
    payload
        ? localStorage.setItem('negociotipo', JSON.stringify(payload))
        : localStorage.removeItem('negociotipo');
  },

  async getNegociosTodos(){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Negocios');
    try {
      const data = await axiosData(
          true,
          'negocios/todos',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('business/setNegocio', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getNegocioById(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Negocio');
    try {
      const data = await axiosData(
          true,
          'negocios/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('business/setNegocio', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getNegocioFormularioByTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Formulario');
    try {
      const data = await axiosData(
          true,
          'negocio/formulario/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getNegocioByTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Negocio');
    try {
      const data = await axiosData(
          true,
          'negocios/tag/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('business/setNegocio', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getTipoListabyNegocio(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Tipo de Listas');
    try {
      const data = await axiosData(
          true,
          'tipolistas/negocio/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);

        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getFiestasNegocioByTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Fiestas');
    try {
      const data = await axiosData(
          true,
          'promotores/negocio/lista/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getPromotoresNegocioLista(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Promotores');
    try {
      const data = await axiosData(
          true,
          'promotores/negocio/lista/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getNegocioDoorCodesByTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Codigos de Puerta');
    try {
      const data = await axiosData(
          true,
          'negocios/codigopuerta/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async deleteNegocioDoorCodesById(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Borrando Codigo');

    try {
      const data = await axiosData(
          true,
          'negocios/codigopuerta/'+id,
          'delete',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getNegocioFacturas(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Trae Facturas');

    try {
      const data = await axiosData(
          true,
          'negocios/facturas',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== 400 && data !== 409){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        if(data === 409){
          this.dispatch('ui/handleLoaderBtn', false);
          return 409;
        }
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async saveNegocioDoorCodes(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'negocios/codigopuerta/grabar',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== 400 && data !== 409){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
      if(data === 409){
        this.dispatch('ui/handleLoaderBtn', false);
        return 409;
      }
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async setNegocioLogo(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const bodyFormData = new FormData();
      bodyFormData.append("file",payload.imagen);
      const data = await axiosData(
          true,
          'negocios/logo/'+payload.negocioId+'/'+payload.size,
          bodyFormData,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async saveNegocio(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'negocios/grabar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('session/setNegocio',payload);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
};

const mutations = {
  SET_NEGOCIOS(state, payload) {
    state.negocios = payload;
    localStorage.setItem('negocios', JSON.stringify(payload));

  },
  SET_NEGOCIO(state, payload) {
    state.negocio = payload;
    localStorage.setItem('negocio', JSON.stringify(payload));
  },
  SET_PERMISOS(state, payload) {
    state.permisos = payload;
    localStorage.setItem('permisos', JSON.stringify(payload));

  },
  SET_PERMISO(state, payload) {
    state.permiso = payload;
    localStorage.setItem('permiso', JSON.stringify(payload));
  },
  SET_NEGOCIOPROMOTOR(state, payload) {
    state.negociopromotor = payload;
    localStorage.setItem('negociopromotor', JSON.stringify(payload));
  },
  SET_ISNEGOCIO(state, payload) {
    state.isnegocios = payload;
    localStorage.setItem('isnegocios', JSON.stringify(payload));
  },
  SET_NEGOCIOTIPO(state, payload) {
    state.negociotipo = payload;
    localStorage.setItem('negociotipo', JSON.stringify(payload));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
